<template>
  <div class="boxfield" v-html="htmltext"></div>
</template>

<script>
import { getAreaSubscribeDetail, getAreaVisitDetail } from '@/api/user'
export default {
  data() {
    return {
      htmltext: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    const flag = this.$route.query.flag
    if (flag === '1') {
      this.getAreaSubscribeDetail()
    } else {
      this.getAreaVisitDetail()
    }
  },
  methods: {
    async getAreaSubscribeDetail() {
      const { data } = await getAreaSubscribeDetail({
        id: this.id
      })
      console.log(data.data.detailTxt)
      this.htmltext = data.data.detailTxt
    },
    async getAreaVisitDetail() {
      const { data } = await getAreaVisitDetail({
        id: this.id
      })
      console.log(data.data.detailTxt)
      this.htmltext = data.data.detailTxt
    }
  }
}
</script>

<style>
.boxfield {
  display: block;
  max-width: 100vw;
}
</style>
